import React from 'react'
import styled from 'styled-components'
import { Container, Navbar, NavItem, NavLink } from 'react-bootstrap';
import Logo from '../images/icon.png'
import './css/header.css'

function Header() {
    return (
        <HeaderDiv className="navbarpart navbar navbar-expand-md bg-light sticky-top">
            <Container>
                <NavBrand href="#" className="navbar-brand"><LogoImg src={Logo} alt="logo" /></NavBrand>
                <Navbar className="navbar-nav">
                    <NavItem>
                        <NavLink href="#" target="_top">Home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#about">About</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#services">Services</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#works">Works</NavLink>
                    </NavItem>
                    <HvrBounceBtn href="#contact" className="btn btn-secondary btn-md hvr-bounce-to-right contactbtn">
                        <p className="nav-link">Contact</p>
                    </HvrBounceBtn>

                </Navbar>
            </Container>
        </HeaderDiv>
    )
}

const HeaderDiv = styled.nav``;

const LogoImg = styled.img``;

const NavBrand = styled.a``;

const HvrBounceBtn = styled.a``;


export default Header