import React from 'react'
import './css/services.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode, faMobileAlt, faPencilRuler } from '@fortawesome/free-solid-svg-icons'


function Services() {
    return (

        <div className="services" id="services">
            <div className="container">
                <h3 className="servicessectiontitle title">Services you can get from me</h3>

                <div className="row servicescontainer">

                    <div className="servicecontainer col-md">
                        <div className="service hvr-bounce-to-top">
                            <div className="imgsection">
                                {/* <i className="fad fa-code"></i> */}
                                <FontAwesomeIcon icon={faCode} size="2x" color="#808080" />
                            </div>
                            <div className="heading">
                                Web Development
                    </div>
                            <div className="details">
                                I am exerienced in making various types of websites. Legal firm website, Business firm
                                website, Portfolio website, and many more.
                    </div>
                        </div>
                    </div>

                    <div className="servicecontainer col-md">
                        <div className="service hvr-bounce-to-top">
                            <div className="imgsection">
                                {/* <i className="fas fa-mobile-alt"></i> */}
                                <FontAwesomeIcon icon={faMobileAlt} size="2x" color="#808080" />

                            </div>
                            <div className="heading">
                                Mobile App Development
                    </div>
                            <div className="details">
                                I am exerienced in making various types of websites. Legal firm website, Business firm
                                website, Portfolio website, and many more.
                    </div>
                        </div>
                    </div>

                    <div className="servicecontainer col-md">
                        <div className="service hvr-bounce-to-top">
                            <div className="imgsection">
                                {/* <i className="fas fa-pencil-ruler"></i> */}
                                <FontAwesomeIcon icon={faPencilRuler} size="2x" color="#808080" />

                            </div>
                            <div className="heading">
                                UI/UX Design
                    </div>
                            <div className="details">
                                I am exerienced in making various types of websites. Legal firm website, Business firm
                                website, Portfolio website, and many more.
                    </div>
                        </div>
                    </div>




                </div>

            </div>
        </div >

    )
}

export default Services