import React from 'react'
import './css/about.css'


function About() {
    return (
        <div className="about" id="about">
            <div className="container">
                <h3 className="aboutsectiontitle title">About your expectations</h3>
                <div className="row">
                    <div className="col-6">

                        <p className="aboutsectiontext">
                            Using huge skillsets to achieve all your
                            goals will make you shine. Everything is done
                            precisely with perfection.
                    </p>
                    </div>
                    <div className="col-6">
                        <div className="skillset">
                            <div className="skilltext">
                                <p className="skillname">HTML & CSS -</p>
                                <p className="skillpercent">80%</p>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: "80%" }} aria-valuenow="80"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className="skillset">
                            <div className="skilltext">
                                <p className="skillname">Design -</p>
                                <p className="skillpercent">90%</p>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: "90%" }} aria-valuenow="90"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className="skillset">
                            <div className="skilltext">
                                <p className="skillname">JavaScript -</p>
                                <p className="skillpercent">90%</p>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: "90%" }} aria-valuenow="90"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className="skillset">
                            <div className="skilltext">
                                <p className="skillname">React -</p>
                                <p className="skillpercent">80%</p>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: "80%" }} aria-valuenow="80"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About