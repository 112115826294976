import React, { useState, useEffect } from 'react'
import './css/works.css'
import Slider from "react-slick";


function Works() {
    let slider1;
    let slider2;
    const [nav1, setNav1] = useState(null)
    const [nav2, setNav2] = useState(null)

    useEffect(() => {
        setNav1(slider1)
        setNav2(slider2)
    }, [slider1, slider2])

    const settings1 = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: false,
        adaptiveHeight: true,
        // asNavFor: "slider-nav",
        infinite: false,
        useTransform: true,
        speed: 400,
        cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
    }
    const settings2 = {
        slidesToShow: 7,
        slidesToScroll: 7,
        dots: false,
        focusOnSelect: false,
        infinite: false,
        // asNavFor: "slider-single",
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
            }
        }, {
            breakpoint: 640,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        }, {
            breakpoint: 420,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        }]
    }
    return (
        <div className="works" id="works">
            <div className="container">
                <h3 className="servicessectiontitle title">Works I have done</h3>

                <div id="page">
                    <div className="row">
                        <div className="column small-11 small-centered">
                            <Slider
                                // {...settings1}
                                asNavFor={nav2}
                                ref={slider => (
                                    slider1 = slider
                                )}
                            >
                                <div>
                                    <h3>1</h3>
                                </div>
                                <div>
                                    <h3>2</h3>
                                </div>
                                <div>
                                    <h3>3</h3>
                                </div>
                                <div>
                                    <h3>4</h3>
                                </div>
                                <div>
                                    <h3>5</h3>
                                </div>
                                <div>
                                    <h3>6</h3>
                                </div>
                                <div>
                                    <h3>7</h3>
                                </div>
                                <div>
                                    <h3>8</h3>
                                </div>
                                <div>
                                    <h3>9</h3>
                                </div>
                                <div>
                                    <h3>10</h3>
                                </div>
                            </Slider>
                            <Slider
                                asNavFor={nav1}
                                ref={slider => (slider2 = slider)}
                                // {...settings2}
                                slidesToShow={3}
                                swipeToSlide={true}
                                focusOnSelect={true}
                            >
                                <div>
                                    <h3><span>1</span></h3>
                                </div>
                                <div>
                                    <h3><span>2</span></h3>
                                </div>
                                <div>
                                    <h3><span>3</span></h3>
                                </div>
                                <div>
                                    <h3><span>4</span></h3>
                                </div>
                                <div>
                                    <h3><span>5</span></h3>
                                </div>
                                <div>
                                    <h3><span>6</span></h3>
                                </div>
                                <div>
                                    <h3><span>7</span></h3>
                                </div>
                                <div>
                                    <h3><span>8</span></h3>
                                </div>
                                <div>
                                    <h3><span>9</span></h3>
                                </div>
                                <div>
                                    <h3><span>10</span></h3>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>




                <button type="button" data-role="none" className="slick-next slick-arrow" aria-label="Next" role="button"
                    style={{ display: "block" }}>Next</button>

            </div>




        </div>
    )
}

export default Works