import React from 'react'
import { Header, Hero, About, Services, Works, Footer } from './components'

function App() {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Services />
      <Works />
      <Footer />
    </>
  )
}

export default App
