import React from 'react'
import './css/hero.css'
import HeroImg from '../images/h1_hero.jpg'
import styled from 'styled-components'

function Hero() {
    return (
        <HeroDiv className="hero">
            <div className="container">
                <div className="herodiv">
                    <p className="heromotto">OUT OF THE BOX</p>
                    <h3 className="herotitle">I am developer <br /> Abhishek R. Pandey</h3>
                    <p className="herodesc">jhorem rfpsum golor sidt amet, consectetur adipiscing elit, eiusmod tempor
                    incididunt utcjhg labore bet dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <button className="btn btn-primary btn-md hvr-bounce-to-right2 hirebtn">Hire Me</button>
                </div>
            </div>
        </HeroDiv>
    )
}

const HeroDiv = styled.div`
    background-image: url(${HeroImg});

`;


export default Hero