import React from 'react'
import './css/footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function Footer() {
    return (
        <>
            <footer className="footer" id="contact">

                <div className="container">
                    <div className="footerpart">
                        <div className="row">
                            <div className="col-6 firstcolumn">
                                <h3 className="title footertitle">Get in touch</h3>
                                <div className="contactcontainer">
                                    {/* <i className="fas fa-envelope"></i> */}
                                    <FontAwesomeIcon icon={faEnvelope} size="2x" color="#808080" />

                                    <div className="detail">
                                        <h3 className="detailhead">Email</h3>
                                        <p className="detailbody">portfolio@abee-tech.com</p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-6">
                                <form action="submit">
                                    <div className="namenemail">
                                        <input type="text" name="First Name" id="firstname" placeholder="Your Name" />
                                        <input type="email" name="Email Address" id="email" placeholder="Your Email" />

                                    </div>
                                    <textarea name="Message" id="message" cols="30" rows="5"
                                        placeholder="Your Message"></textarea>
                                    <button type="submit" className="btn btn-primary btn-md hvr-bounce-to-right2 hirebtn">Send
                                Message</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
            <div className="copyright">
                <div className="container">

                    <h3 className="copyrighttext">Copyright &copy; 2021 | All rights reserved</h3>
                </div>

            </div>
        </>
    )
}

export default Footer